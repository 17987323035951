import Link from 'next/link';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '@/components/ui/Button/Button';
import OnlyDesktop from '@/components/ui/OnlyDesktop/OnlyDesktop';
import OnlyMobile from '@/components/ui/OnlyMobile/OnlyMobile';

import {
  ImageWrapper,
  InformationText,
  NotFoundImage,
  NotFoundMobileImage,
  Wrapper,
} from './NotFound.style';

/**
 * NotFound.
 */
const NotFound = () => (
  <React.Fragment>
    <Wrapper>
      <ImageWrapper>
        <OnlyDesktop>
          <NotFoundImage data-cy="image: construction" />
        </OnlyDesktop>

        <OnlyMobile>
          <NotFoundMobileImage data-cy="image: construction" />
        </OnlyMobile>
      </ImageWrapper>

      <InformationText data-cy="text: information">
        <FormattedMessage id="404_text" />
      </InformationText>
      <Button
        size="large"
        fullWidth
        sx={{ maxWidth: 254 }}
        data-cy="button: cta"
        component={Link}
        href="/">
        <FormattedMessage id="404_cta" />
      </Button>
    </Wrapper>
  </React.Fragment>
);

export default NotFound;
