import { styled } from '@mui/material/styles';

import NotFoundImageBase from '@/assets/not-found.svg';
import NotFoundMobileImageBase from '@/assets/not-found-mobile.svg';

export const Wrapper = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  margin: '150px auto 217px auto',
  cursor: 'pointer',

  [theme.breakpoints.down(468)]: {
    maxWidth: '268px',
  },

  [theme.breakpoints.down('md')]: {
    marginTop: '28px',
    marginBottom: '168px',
  },
}));

export const ImageWrapper = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
}));

export const InformationText = styled('p')(({ theme }) => ({
  color: theme.palette.grayscale[800],
  fontSize: '24px',
  fontWeight: 500,
  lineHeight: 1.5,
  letterSpacing: '0.47px',
  textAlign: 'center',
  marginTop: '29px',
  marginBottom: '50px',

  [theme.breakpoints.up('md')]: {
    marginBottom: '79px',
  },
}));

export const NotFoundImage = styled(NotFoundImageBase)(() => ({
  width: '100%',
}));

export const NotFoundMobileImage = styled(NotFoundMobileImageBase)(() => ({
  width: '100%',
}));
